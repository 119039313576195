import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import PullToRefresh from "pulltorefreshjs"

const app = createApp(App);

app.mount('#app');

const standalone = window.matchMedia("(display-mode: standalone)").matches

if (standalone) {
  PullToRefresh.init({
    onRefresh() {
      window.location.reload()
    },
  })
}
