<template>
<div class="product-grid col-lg-3 col-md-3 col-sm-4 col-xs-6">
  <div class="product-item item-category">
    <div class="image label-image-change">
      <a :href='`https://www.odkarla.cz${url}`' target="_blank">
        <div class="img-listing-wrap">
          <img  :src="image" class="img-responsive">
        </div>
      </a>
    </div>
    <div class="price-and-discount-wrap">
      <div class="price-button-listing js-product-detail-browse" >{{ price }}</div>
      <div class="price-discount-tag"></div>
            <div class="copy-link">
      <a href="#" @click.prevent="copyToClipboard">
        Copy URL
      </a>
    </div>

    </div>
    <div class="filter-heading-container">
      <h3 class="filter-heading">
        <a :href='`https://www.odkarla.cz${url}`' target="_blank">{{ name }}</a>
      </h3>
      <div class="category-product-minidescription">
        {{ description }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyToClipboard() {
      const url = `https://www.odkarla.cz${this.url}`;
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
  },

};
</script>
