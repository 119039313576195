<template>
  <div class="row products-row category-product category-change-11 filter-page-list category-page js-product-grid">
    <ProductDetail
      v-for="(row) in products" :key="row.id"
      :name="row.name"
      :description="row.description"
      :price="row.price"
      :url="row.url"
      :image="row.image"
     />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { supabase } from './lib/supabaseClient'

import ProductDetail from './components/ProductDetail.vue'

const products = ref([])

async function getProducts() {
  const { data } = await supabase.from('products').select().order('id', { ascending: false }).limit(100)

  products.value = data
}

// Subscribe to real-time changes
const subscribeToProducts = () => {
  const channel = supabase
    .channel('schema-db-changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'products',
      },
      (payload) => {
        switch (payload.eventType) {
          case 'INSERT': {
            products.value.unshift(payload.new)
            break
          }
          case 'UPDATE': {
            const indexToUpdate = products.value.findIndex(product => product.id === payload.new.id)
            if (indexToUpdate !== -1) {
              products.value[indexToUpdate] = payload.new
            }
            break
          }
          case 'DELETE': {
            products.value = products.value.filter(product => product.id !== payload.old.id)
            break
          }
          default:
            break
        }
      }
    )
    .subscribe()

  return channel
}

onMounted(() => {
  getProducts();
  const channel = subscribeToProducts()

  // Cleanup on unmount
  onBeforeUnmount(() => {
    supabase.removeChannel(channel)
  })

})
</script>
